import { connect } from 'react-redux';
import { emoji_reaction, unemoji_reaction } from 'mastodon/actions/interactions';
import StatusEmoji_ReactionBar from '../components/status_emoji_reaction_bar';
import { createSelector } from 'reselect';
import { Map as ImmutableMap } from 'immutable';

const customEmojiMap = createSelector([state => state.get('custom_emojis')], items => items.reduce((map, emoji) => map.set(emoji.get('shortcode'), emoji), ImmutableMap()));

const mapStateToProps = state => ({
  emojiMap: customEmojiMap(state),
});

const mapDispatchToProps = dispatch => ({
  addReaction: (status, name) => dispatch(emoji_reaction(status, name)),
  removeReaction: (status) => dispatch(unemoji_reaction(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusEmoji_ReactionBar);
