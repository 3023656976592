import React from 'react';
import ImmutablePureComponent from 'react-immutable-pure-component';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { autoPlayGif, reduceMotion } from 'mastodon/initial_state';
import unicodeMapping from 'mastodon/features/emoji/emoji_unicode_mapping_light';
import classNames from 'classnames';
import {AnimatedNumber} from 'mastodon/components/animated_number';
import TransitionMotion from 'react-motion/lib/TransitionMotion';
import spring from 'react-motion/lib/spring';
import { assetHost } from 'mastodon/utils/config';
import Overlay from 'react-overlays/Overlay';
import {Avatar} from './avatar';
import {DisplayNamexx} from './display_namexx';
class Emoji extends React.PureComponent {

  static propTypes = {
    emoji: PropTypes.string.isRequired,
    emojiMap: ImmutablePropTypes.map.isRequired,
    hovered: PropTypes.bool.isRequired,
    domain: PropTypes.string,
    url: PropTypes.string,
    static_url: PropTypes.string,
  };

  render () {
    const { emoji, hovered, domain, url, static_url } = this.props;

    if (unicodeMapping[emoji]) {
      const { filename, shortCode } = unicodeMapping[this.props.emoji];
      const title = shortCode ? `:${shortCode}:` : '';

      return (
        <img
          draggable='false'
          className='emojione'
          alt={emoji}
          title={title}
          src={`${assetHost}/emoji/${filename}.svg`}
          loading='lazy'
          decoding='async'
        />
      );
    } else {
      const filename = (autoPlayGif || hovered) ? url : static_url;
      const shortCode = `:${emoji}:`;
      const title = domain ? `:${emoji}@${domain}:` : `:${emoji}:`;
      return (
        <img
          draggable='false'
          className='emojione custom-emoji'
          alt={shortCode}
          title={title}
          src={filename}
          loading='lazy'
          decoding='async'
        />
      );

    }
  }

}

class Emoji_Reaction extends ImmutablePureComponent {

  static propTypes = {
    status: ImmutablePropTypes.map.isRequired,
    signedIn: PropTypes.bool.isRequired,
    emoji_reaction: ImmutablePropTypes.map.isRequired,
    addEmoji_Reaction: PropTypes.func.isRequired,
    removeEmoji_Reaction: PropTypes.func.isRequired,
    emojiMap: ImmutablePropTypes.map.isRequired,
    style: PropTypes.object,
    disabled: PropTypes.bool,
  };

  state = {
    hovered: false,
  };

  handleClick = () => {
    const { emoji_reaction, status, addEmoji_Reaction, removeEmoji_Reaction } = this.props;

    if (status.get('reacted')) {
      removeEmoji_Reaction(status, emoji_reaction.get('name'));
    } else {
      addEmoji_Reaction(status, emoji_reaction.get('name'));
    }
  };

  handleMouseEnter = () => this.setState({ hovered: true });

  handleMouseLeave = () => this.setState({ hovered: false });
  setTargetRef = c => {
    this.target = c;
  };
  findTarget = () => {
    return this.target;
  };
  render () {
    const { emoji_reaction, signedIn } = this.props;
    const { hovered } = this.state;
    let shortCode = emoji_reaction.get('name');
    let title;
    const domain = emoji_reaction.get('domain');
    if (unicodeMapping[shortCode]) {
      shortCode = unicodeMapping[shortCode].shortCode;
      title = `:${shortCode}:`;
    } else {
      title = domain ? `:${shortCode}@${domain}:` : `:${shortCode}:`;
    }

    return (
      <React.Fragment>
        <span ref={this.setTargetRef} className='status-emoji_reaction-bar__wrapper' onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
          <button className={classNames('status-emoji_reaction-bar__item', { active: emoji_reaction.get('me') })} disabled={emoji_reaction.get('domain') || !signedIn} onClick={this.handleClick} title={title} style={this.props.style}>
            <span className='status-emoji_reaction-bar__item__emoji'><Emoji hovered={hovered} emoji={emoji_reaction.get('name')} emojiMap={this.props.emojiMap} domain={emoji_reaction.get('domain')} url={emoji_reaction.get('url')} static_url={emoji_reaction.get('static_url')} signedIn={signedIn} /></span>
        <span className='status-emoji_reaction-bar__item__count'><AnimatedNumber value={emoji_reaction.get('count')} /></span>
      </button>
                  </span>
        <Overlay show={hovered} offset={[0, 5]} placement={'top'} flip target={this.findTarget} popperConfig={{ strategy: 'fixed' }}>
          {({ props, placement }) => (
            <div {...props} >
              <div class={`dropdown-animation ${placement}`}>
                <div class='status-emoji_reaction-bar__item__users'>
                  <div className='status-reaction-bar__item__users__emoji'>
                    <span><Emoji hovered={this.state.hovered} emoji={emoji_reaction.get('name')} emojiMap={this.props.emojiMap} domain={emoji_reaction.get('domain')} url={emoji_reaction.get('url')} static_url={emoji_reaction.get('static_url')} signedIn={signedIn} /></span>
                    <span className='status-emoji_reaction-bar__item__users__emoji__code'>{title}</span>
                  </div>
                  <div>
                  {console.log(emoji_reaction.get('users'))}
                    {emoji_reaction.get('users').map(user => (
                      <span className='status-emoji_reaction-bar__item__users__item' key={user.get('acct')}>
                        <Avatar account={user} size={24} />
                        <DisplayNamexx account={user} />
                      </span>
                    ))}
                    {emoji_reaction.get('count') > 11 && (
                      <span className='status-emoji_reaction-bar__item__users__item'>
                        +{emoji_reaction.get('count') - 11}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Overlay>
      </React.Fragment>
    );
  }

}

//export default @injectIntl
class StatusEmojiReactionBar extends ImmutablePureComponent {

  static propTypes = {
    status: ImmutablePropTypes.map.isRequired,
    signedIn: PropTypes.bool.isRequired,
    addEmoji_Reaction: PropTypes.func.isRequired,
    removeEmoji_Reaction: PropTypes.func.isRequired,
    emojiMap: ImmutablePropTypes.map.isRequired,
  };

  willEnter () {
    return { scale: reduceMotion ? 1 : 0 };
  }

  willLeave () {
    return { scale: reduceMotion ? 0 : spring(0, { stiffness: 170, damping: 26 }) };
  }

  render () {
    const status = this.props.status;
    const signedIn = this.props.signedIn;
    const emoji_reactions = status.get('emoji_reactions');
    const visibleEmoji_Reactions = emoji_reactions.filter(x => x.get('count') > 0);

    const styles = visibleEmoji_Reactions.map(emoji_reaction => ({
      key: emoji_reaction.get('name') + '@' + emoji_reaction.get('domain'),
      data: emoji_reaction,
      style: { scale: reduceMotion ? 1 : spring(1, { stiffness: 150, damping: 13 }) },
    })).toArray();

    return (
      <TransitionMotion styles={styles} willEnter={this.willEnter} willLeave={this.willLeave}>
        {items => (
          <div className={classNames('status-emoji_reaction-bar', { 'status-emoji_reaction-bar--empty': visibleEmoji_Reactions.isEmpty() })}>
            {items.map(({ key, data, style }) => (
              <Emoji_Reaction
                key={key}
                emoji_reaction={data}
                style={{ transform: `scale(${style.scale})`, position: style.scale < 0.5 ? 'absolute' : 'static' }}
                status={status}
                signedIn={signedIn}
                addEmoji_Reaction={this.props.addEmoji_Reaction}
                removeEmoji_Reaction={this.props.removeEmoji_Reaction}
                emojiMap={this.props.emojiMap}
              />
            ))}
          </div>
        )}
      </TransitionMotion>
    );
  }

}
export default injectIntl(StatusEmojiReactionBar);
